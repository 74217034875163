import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'
import firebase from 'firebase/app'
import 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/analytics'

import * as serviceWorker from './serviceWorker'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import {Provider} from 'react-redux'
import rootReducer from './store/reducers/rootReducer'
import { ReactReduxFirebaseProvider, getFirebase } from 'react-redux-firebase'
import { createFirestoreInstance, getFirestore, reduxFirestore } from 'redux-firestore'
import {BrowserRouter} from 'react-router-dom'

const fbConfig= {
  apiKey: "AIzaSyC3YyAO1V3tA0UdoeONYEy8v3-y2yttqrY",
  authDomain: "phoenix-meatball.firebaseapp.com",
  databaseURL: "https://phoenix-meatball.firebaseio.com",
  projectId: "phoenix-meatball",
  storageBucket: "phoenix-meatball.appspot.com",
  messagingSenderId: "579910250048",
  appId: "1:579910250048:web:6ec5d83bb03716611bf9a5",
  measurementId: "G-9WSLQJHEJD"
}

// Initialize Firebase
firebase.initializeApp(fbConfig)
firebase.firestore()
firebase.analytics()

const createStoreWithFirebase = compose(
  applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
  reduxFirestore(fbConfig),
  
)(createStore);

// Create store with reducers and initial state
const initialState = {}
const store = createStoreWithFirebase(rootReducer, initialState);

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider 
            {...rrfProps}
        >
        <BrowserRouter>
          <App />
        </BrowserRouter>
    </ReactReduxFirebaseProvider>

    
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
