import React, {Component} from 'react'
import './index.css'
import { ThemeProvider } from '@material-ui/styles'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import { createMuiTheme } from '@material-ui/core/styles'

import Homepage from './pages/homepage'

const theme = createMuiTheme({
  root:{
    margin:0
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#2AC9AC',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
      contrastText: "white"
    },
    secondary: {
      main: '#000000',
    },   
    // error: will use the default color
  },
});

class App extends Component{
  render(){
    return(
      <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Switch>
              <Route 
                exact
                path="/"
                component={Homepage}
              />
            </Switch>
          </BrowserRouter>
      </ThemeProvider>
    )
  }
}

export default App;
