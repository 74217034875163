import React, { Component } from 'react'
import styles from './Cover.module.css'

class Cover extends Component {
    render(){
        //get correct background image based on type
        var imgUrl = `/images/phoenix-meatball-logo.png`;
        
        const backgroundImage = {
            //backgroundImage:`url('${imgUrl}')`,
            backgroundColor:'black', 
            width:'100%'
        }

        return(
            <div className={styles.container} style={backgroundImage}>
                <div className={styles.content} >
                    <div className={styles.contentText}>
                        <img src='/images/phoenix-meatball-logo.png' width='100%'/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Cover;
