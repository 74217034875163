import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import ReactPlayer from 'react-player'

const useStyles = makeStyles({
    videoImage:{
        backgroundColor:'#000',

    },
    videoItem:{

    }
})

export default function VideoItem(props) {
    const classes = useStyles()
    return(
        <Box className={classes.videoItem} mt={10}>
            <Typography
                component="h4"
                variant="h4"
                style={{ width: '100%', position: 'relative' }}
            >
                {props.videoTitle}
            </Typography>
            <Box className={classes.videoImage}>
                <ReactPlayer url={props.videoUrl} width='100%' height='100%' controls={true} />
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Typography
                        component="div"
                        variant="body2"
                    >
                        {props.videoStatus}
                    </Typography>
                </Grid>
                <Grid item xs={6} >
                    <Typography
                        component="div"
                        variant="body2"
                        align="right"
                    >
                        {props.videoLength}
                    </Typography>
                </Grid>
            </Grid>

        </Box>
    )
}