import React from 'react'
import Grid from '@material-ui/core/Grid'
import Cover from '../components/cover/Cover'
import Container from '@material-ui/core/Container'
import VideoItem from '../components/video/videoItem'

const homepage = () => {
    return(
        <Grid>
            <Grid item xs={12} >
                <Cover 
                    type="homepage"
                    title="TRAIN WITH THE BEST"
                    subTitle="A NEW AND EASY WAY TO TRAIN. COMING SOON"
                    button="Start your workout"
                    anchor="#fitnesspro"
                />
            </Grid>
            <Container maxWidth="md" padding={10}>       
                <VideoItem 
                    videoTitle='Phoenix meatball -Intro Sprint 17'
                    videoUrl='videos/intro-phoenix-meatball-Groundhog-Day-v02.mp4'
                />
            </Container>
        </Grid>
    )
}

export default homepage